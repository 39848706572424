import { generateUUID } from 'three/src/math/MathUtils';
import { sendXRAnalyticsData } from '../services';
import {
  DEFAULT_INTERVAL_VALUE,
  MAX_INTERVAL_VALUE,
  MAXIMUM_BATCH_RECORDS_LENGTH,
  MAXIMUM_BATCH_SENDING_INTERVAL,
  MAXIMUM_SESSION_KEEPALIVE_TIME,
  MIN_INTERVAL_VALUE,
} from '../constants';
import { MetalitixLoggerOptions, SurveyTheme, XRAnalytics } from '../types';
import { deepEqual, systemInfo } from '../utils';
import { addSurvey } from './mtx-engagement-survey';

export default abstract class MetalitixLoggerBase<T> {
  private appKey: string;
  private apiVersion: string;
  private interval: number = DEFAULT_INTERVAL_VALUE;
  protected sessionId: string | null;
  private pollRecords: XRAnalytics.Record[];
  private lastPollTimestamp: number;
  private nextPoll: number;
  private pollInProgress: boolean;
  private previousCameraData: XRAnalytics.UserCamera | null;
  private customData: { [key: string]: any } = {};
  private userMeta: Partial<XRAnalytics.UserMetadata>;
  private showSurveyAutomatically: boolean;
  private surveyTheme?: SurveyTheme;
  private autoSurveyShowInMs: number;
  private surveyTimer: number;
  private prevFPSTime: number;
  private frames: number;
  private currentFPS: number;
  private loopFPSRequestId: number;
  private previousAnimations: { [key: string]: XRAnalytics.Animation } = {};
  protected object3D: T | null = null;

  constructor(appKey: string, options: MetalitixLoggerOptions = {}) {
    const {
      pollInterval = DEFAULT_INTERVAL_VALUE,
      apiVersion = 'v2',
      userMeta = {},
      showSurvey = true,
      surveyTheme,
    } = options;
    this.appKey = appKey;
    this.apiVersion = apiVersion;
    this.userMeta = userMeta;
    this.setPollInterval(pollInterval);
    this.sessionId = null;
    this.previousCameraData = null;
    this.pollRecords = [];
    this.lastPollTimestamp = -1;
    this.pollInProgress = false;
    this.nextPoll = -1;
    this.pollInProgress = false;
    this.showSurveyAutomatically = showSurvey;
    this.surveyTheme = surveyTheme;
    /** Show survey automatically in range between 30 seconds and 3 minutes */
    this.autoSurveyShowInMs = (30 + 150 * Math.random()) * 1000;
    this.surveyTimer = -1;
    this.prevFPSTime = Date.now();
    this.frames = 0;
    this.currentFPS = 0;
    this.loopFPSRequestId = -1;
  }

  public setPollInterval = (pollInterval: number) => {
    this.interval = Math.min(MAX_INTERVAL_VALUE, Math.max(MIN_INTERVAL_VALUE, pollInterval));
  };

  private updateFPS = () => {
    this.frames++;

    let time = Date.now();

    if (time >= this.prevFPSTime + 1000) {
      this.currentFPS = (this.frames * 1000) / (time - this.prevFPSTime);

      this.prevFPSTime = time;
      this.frames = 0;
    }
  };

  private loopFPS = () => {
    this.loopFPSRequestId = requestAnimationFrame(() => {
      this.updateFPS();
      this.loopFPS();
    });
  };

  private stopFPSLoop = () => {
    cancelAnimationFrame(this.loopFPSRequestId);
  };

  public setCustomField = (key: string, value: any) => {
    this.customData[key] = value;
  };

  public removeCustomField = (key: string) => {
    delete this.customData[key];
  };

  private getRecord = (
    eventType: XRAnalytics.EventTypes,
    sessionId: string,
    { userEvent, userMeta, camera, data }: XRAnalytics.RecordDataFields,
  ) => {
    const resultData = Object.assign({}, this.customData, data);

    const currentAnimations = this.getAnimationsData();
    const animations: XRAnalytics.Animation[] = [];
    currentAnimations.forEach(animation => {
      const previousAnimation = this.previousAnimations[animation.name];

      if (previousAnimation === undefined || !deepEqual(previousAnimation, animation)) {
        animations.push(animation);
      }

      this.previousAnimations[animation.name] = animation;
    });

    const base: XRAnalytics.RecordBase = {
      object: 'xr.analytics.record',
      appkey: this.appKey,
      apiver: this.apiVersion,
      sessionId,
      timestamp: Date.now(),
      animations,
      data: resultData,
      userMeta,
    };

    if (this.currentFPS > 0) {
      base.metrics = base.metrics ?? {};
      base.metrics.fps = Math.round(this.currentFPS);
    }

    if (eventType === XRAnalytics.EventTypes.SessionStart) {
      console.assert(camera !== undefined, '"camera" is required for session start!');
      return Object.assign({}, base, { eventType, camera }) as XRAnalytics.SessionStartRecord;
    }

    if (eventType === XRAnalytics.EventTypes.SessionUpdate) {
      return Object.assign({}, base, { eventType, camera }) as XRAnalytics.SessionUpdateRecord;
    }

    if (eventType === XRAnalytics.EventTypes.SessionEnd) {
      return Object.assign({}, base, { eventType, camera }) as XRAnalytics.SessionEndRecord;
    }

    if (eventType === XRAnalytics.EventTypes.UserPosition) {
      return Object.assign({}, base, { eventType }) as XRAnalytics.UserPositionRecord;
    }

    if (eventType === XRAnalytics.EventTypes.UserInteraction) {
      console.assert(userEvent !== undefined, '"userEvent" is required for user interaction!');
      return Object.assign({}, base, { eventType, userEvent }) as XRAnalytics.UserInteractionRecord;
    }

    throw new Error('Unknown eventType: ' + eventType);
  };

  private addRecord = (
    eventType: XRAnalytics.EventTypes,
    { userEvent, userMeta, camera, data }: XRAnalytics.RecordDataFields,
  ) => {
    if (this.sessionId === null) {
      return;
    }

    const record = this.getRecord(eventType, this.sessionId, { userEvent, userMeta, camera, data });

    this.pollRecords.push(record);
  };

  private sendPosition = async (sendAll = false) => {
    this.pollInProgress = true;

    try {
      const items = this.pollRecords.slice(0, MAXIMUM_BATCH_RECORDS_LENGTH);

      const batchRecordsData = {
        object: 'xr.analytics.batch.records',
        appkey: this.appKey,
        apiver: this.apiVersion,
        items,
      };

      await sendXRAnalyticsData(batchRecordsData);
      this.pollRecords = this.pollRecords.slice(MAXIMUM_BATCH_RECORDS_LENGTH);

      if (this.pollRecords.length > 0 && sendAll) {
        await this.sendPosition(true);
      }

      this.lastPollTimestamp = Date.now();
    } catch (error) {
      console.log('Something went wrong', error);
      this.forceStopLoop();
    } finally {
      this.pollInProgress = false;
    }
  };

  protected abstract getPositionData: (object3D: T | null) => Promise<XRAnalytics.Data | undefined>;
  protected abstract getCameraData: (object3D: T | null) => Promise<XRAnalytics.UserCamera | undefined>;
  protected abstract getAnimationsData: () => XRAnalytics.Animation[];

  private getUserMeta = (): Partial<XRAnalytics.UserMetadata> => {
    return Object.assign({}, this.userMeta, {
      userAgent: window.navigator.userAgent,
      pagePath: location.pathname,
      pageQuery: location.search,
      systemInfo,
    });
  };

  private addSessionStart = async () => {
    const data = await this.getPositionData(this.object3D);
    const camera = await this.getCameraData(this.object3D);
    const userMeta = this.getUserMeta();
    this.previousCameraData = camera || null;

    if (data === undefined) {
      throw new Error('Data is required field!');
    }

    this.addRecord(XRAnalytics.EventTypes.SessionStart, { data, camera, userMeta });
  };
  private addSessionEnd = async () => {
    const data = await this.getPositionData(this.object3D);
    const userMeta = this.getUserMeta();
    let camera = await this.getCameraData(this.object3D);

    if (data === undefined) {
      throw new Error('Data is required field!');
    }

    if (deepEqual(camera, this.previousCameraData)) {
      /** Don't send the camera object if it was not changed */
      camera = undefined;
    }

    this.addRecord(XRAnalytics.EventTypes.SessionEnd, { data, userMeta, camera });
  };
  private addUserPosition = async () => {
    const data = await this.getPositionData(this.object3D);
    const userMeta = this.getUserMeta();

    if (data === undefined) {
      throw new Error('Data is required field!');
    }

    this.addRecord(XRAnalytics.EventTypes.UserPosition, { data, userMeta });
  };
  private addSessionUpdate = async (camera?: XRAnalytics.UserCamera) => {
    const data = await this.getPositionData(this.object3D);
    const userMeta = this.getUserMeta();

    if (data === undefined) {
      throw new Error('Data is required field!');
    }

    this.addRecord(XRAnalytics.EventTypes.SessionUpdate, { data, camera, userMeta });
  };

  private sddNextUserPositionAndUpdateCameraIfNeeded = async () => {
    const camera = await this.getCameraData(this.object3D);

    if (camera === undefined || deepEqual(camera, this.previousCameraData)) {
      this.addUserPosition();
    } else {
      this.addSessionUpdate(camera);
      this.previousCameraData = camera;
    }
  };

  private sendPositionLoop = (start = false) => {
    /** Don't push position twice on session start */
    if (!start) {
      this.sddNextUserPositionAndUpdateCameraIfNeeded();
    }

    if (
      !this.pollInProgress &&
      this.pollRecords.length > 0 &&
      (this.pollRecords.length >= MAXIMUM_BATCH_RECORDS_LENGTH ||
        Date.now() - this.lastPollTimestamp >= MAXIMUM_BATCH_SENDING_INTERVAL)
    ) {
      this.sendPosition();
    }

    /** Stop polling data if session was ended and there is nothing to send */
    if (this.sessionId === null && this.pollRecords.length === 0) {
      return;
    }

    this.nextPoll = window.setTimeout(() => this.sendPositionLoop(), this.interval);
  };

  private forceStopLoop = () => clearTimeout(this.nextPoll);

  private clearSessionPollRecords = () => {
    this.pollRecords = [];
  };

  private handleVisibilityChange = async () => {
    /**
     *  If user close or switch the browser tab - we need to pause the session
     *  Then if user came back to the browser - we need to try to resume the session if it's possible or start new one
     **/
    return document.visibilityState === 'hidden' ? this.pauseSession() : await this.resumeSession();
  };

  public startSession = async (object3D: T) => {
    if (object3D === null || object3D === undefined) {
      console.log('[Metalitix] Parameter is undefined or null when calling startSession()');
      return;
    }

    this.object3D = object3D;
    const sessionId = generateUUID();
    this.sessionId = sessionId;
    await this.addSessionStart();
    this.sendPositionLoop(true);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.loopFPS();

    if (this.showSurveyAutomatically) {
      this.surveyTimer = window.setTimeout(
        () => addSurvey({ appkey: this.appKey, sessionId, theme: this.surveyTheme }),
        this.autoSurveyShowInMs,
      );
    }
  };

  public pauseSession = () => {
    /** If the session was paused we need to send all our data and stop collecting new items */
    this.forceStopLoop();
    return this.sendPosition(true);
  };

  public resumeSession = async () => {
    if (this.lastPollTimestamp < 0 || Date.now() - this.lastPollTimestamp <= MAXIMUM_SESSION_KEEPALIVE_TIME) {
      /** If the session was resumed on time - we need to continue collect current session data */
      this.forceStopLoop(); // in case if session was not paused
      this.sendPositionLoop();
    } else if (this.object3D !== null) {
      /** If the session was resumed when server already has closed the session - we need to start new session */
      const object3D = this.object3D;
      await this.endSession();
      this.startSession(object3D);
    }
  };

  public endSession = async () => {
    if (this.sessionId === null) {
      /** The session was already ended */
      return;
    }

    await this.addSessionEnd();
    this.object3D = null;
    this.sessionId = null;
    this.previousCameraData = null;
    this.customData = {};
    this.forceStopLoop();
    this.stopFPSLoop();
    await this.sendPosition(true);
    this.clearSessionPollRecords();
    this.lastPollTimestamp = -1;
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  };

  private updateUserMeta = (userMeta: Partial<XRAnalytics.UserMetadata>) => {
    if (userMeta === undefined) {
      return;
    }

    this.userMeta = userMeta;

    return this.addSessionUpdate();
  };

  private sendUserEvent = async (
    eventName: string,
    eventType: XRAnalytics.UserInteractionTypes | string,
    target?: string | XRAnalytics.EventPoint | any,
    points?: XRAnalytics.EventPoint[],
    params?: object,
  ) => {
    const data = await this.getPositionData(this.object3D);
    const userEvent: XRAnalytics.UserEvent = {
      object: 'user.event',
      eventName,
      eventType,
      target,
      points,
      params,
    };
    const userMeta = this.getUserMeta();

    if (data === undefined) {
      throw new Error('Data is required field!');
    }

    this.addRecord(XRAnalytics.EventTypes.UserInteraction, { data, userMeta, userEvent });

    return userEvent;
  };

  public logCustomEvent = (eventName: string, params: object) => {
    this.sendUserEvent(eventName, 'custom', undefined, undefined, params);
  };

  public logKeyDownEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'key_down',
      XRAnalytics.UserInteractionTypes.KeyDown,
      {
        state: XRAnalytics.PointStates.Pressed,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logKeyPressEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'key_press',
      XRAnalytics.UserInteractionTypes.KeyPress,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logKeyUpEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'key_up',
      XRAnalytics.UserInteractionTypes.KeyUp,
      {
        state: XRAnalytics.PointStates.Released,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseEnterEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_enter',
      XRAnalytics.UserInteractionTypes.MouseEnter,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseLeaveEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_leave',
      XRAnalytics.UserInteractionTypes.MouseLeave,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseOverEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_over',
      XRAnalytics.UserInteractionTypes.MouseOver,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseOutEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_out',
      XRAnalytics.UserInteractionTypes.MouseOut,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseDownEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_down',
      XRAnalytics.UserInteractionTypes.MouseDown,
      {
        state: XRAnalytics.PointStates.Pressed,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseUpEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_up',
      XRAnalytics.UserInteractionTypes.MouseUp,
      {
        state: XRAnalytics.PointStates.Released,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMouseMoveEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_move',
      XRAnalytics.UserInteractionTypes.MouseMove,
      {
        state: XRAnalytics.PointStates.Updated,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logMousePressEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'mouse_press',
      XRAnalytics.UserInteractionTypes.MousePress,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logTouchTapEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'touch_tap',
      XRAnalytics.UserInteractionTypes.TouchTap,
      {
        state: XRAnalytics.PointStates.Stationary,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logTouchStartEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'touch_start',
      XRAnalytics.UserInteractionTypes.TouchStart,
      {
        state: XRAnalytics.PointStates.Pressed,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logTouchMoveEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'touch_move',
      XRAnalytics.UserInteractionTypes.TouchMove,
      {
        state: XRAnalytics.PointStates.Updated,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logTouchEndEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'touch_end',
      XRAnalytics.UserInteractionTypes.TouchEnd,
      {
        state: XRAnalytics.PointStates.Released,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logZoomStartEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'zoom_start',
      XRAnalytics.UserInteractionTypes.ZoomStart,
      {
        state: XRAnalytics.PointStates.Pressed,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logZoomUpdateEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'zoom_update',
      XRAnalytics.UserInteractionTypes.ZoomUpdate,
      {
        state: XRAnalytics.PointStates.Updated,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public logZoomEndEvent = (x: number, y: number, params?: object) => {
    this.sendUserEvent(
      'zoom_end',
      XRAnalytics.UserInteractionTypes.ZoomEnd,
      {
        state: XRAnalytics.PointStates.Released,
        timestamp: Date.now(),
        position: { x, y },
      },
      undefined,
      params,
    );
  };

  public showSurvey = (surveyTheme?: SurveyTheme) => {
    clearTimeout(this.surveyTimer);

    if (this.sessionId === null) {
      return;
    }

    addSurvey({ appkey: this.appKey, sessionId: this.sessionId, theme: surveyTheme ?? this.surveyTheme, force: true });
  };
}
