import MetalitixLoggerBase from './mtx-poll-base';
import { Vector3 } from 'three/src/math/Vector3';
import { MetalitixLoggerOptions } from '../types';
import { AnimationAction } from 'three/src/animation/AnimationAction';
import { LoopRepeat } from 'three/src/constants';

interface CameraEntity {
  getPosition: () => Vector3;
  getRotation: () => { getEulerAngles: () => Vector3 };
  camera: {
    nearClip: number;
    farClip: number;
    fov: number;
    aspectRatio: number;
  };
}

class MetalitixLogger extends MetalitixLoggerBase<CameraEntity> {
  private animationActions: { [key: string]: AnimationAction } = {};

  constructor(appKey: string, options: MetalitixLoggerOptions = {}) {
    super(appKey, options);
  }

  protected getPositionData = async (cameraEntity: CameraEntity | null) => {
    if (cameraEntity === null) {
      return undefined;
    }
    const position = cameraEntity.getPosition();
    const direction = cameraEntity.getRotation().getEulerAngles();

    return {
      position: { x: position.x, y: position.y, z: position.z },
      direction: { x: direction.x, y: direction.y, z: direction.z },
    };
  };

  protected getCameraData = async (cameraEntity: CameraEntity | null) => {
    if (cameraEntity === null) {
      return undefined;
    }
    return {
      fieldOfView: cameraEntity.camera.fov,
      aspectRatio: cameraEntity.camera.aspectRatio,
      zNearPlane: cameraEntity.camera.nearClip,
      zFarPlane: cameraEntity.camera.farClip,
    };
  };

  protected getAnimationsData = () => {
    return Object.values(this.animationActions).map(action => {
      const clip = action.getClip();

      return {
        name: clip.name,
        progress: action.time / clip.duration,
        weight: action.weight,
        loop: action.loop === LoopRepeat,
      };
    });
  };

  public setAnimation = (action: AnimationAction) => {
    const clipName = action.getClip().name;
    this.animationActions[clipName] = action;
  };
}

export default MetalitixLogger;
